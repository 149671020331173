import React from "react";
import { MainRouter } from "./routes/MainRoutes";
import './App.css'
import './fonts/CiscoSans/CiscoSans.ttf';
import './fonts/CiscoSans/CiscoSansBold.ttf';

export default function App() {

  return (
    <div className="App">
      <MainRouter />
    </div>
  );
}
