import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import teltecLogo from "../../assets/img/logo_teltec.png"
import { Button } from "react-materialize";

export const Success = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="row s12 m12 l12" style={{margin: "0", width: "100%", height: "100%"}}>
      <div className="hide-on-med-and-down" style={{position: 'absolute', bottom:"3.5vh", left:"1.5vw"}}>
        <img src={teltecLogo} alt="Teltec Logo" style={{width: "14vw"}}></img>
      </div>
      <div className="col center-align s12 m12 l12" style={{marginTop: "12vh"}}>
        <div className="row center-align s12 m10 l8" style={{margin: "3vh auto"}}>
          <div className="card-panel white" style={{borderRadius: "35px", margin: "3px"}}>
            <div className="row s12 m12 l12" style={{margin: "5vh 0"}}>
              <h1 style={{ color: "#0054AF", margin: 0, marginBottom: "3vh", textAlign: "center",  "font-family": "CiscoSans", fontSize: "3em" }}>Reunião agendada com <span style={{"font-family": "CiscoSansBold"}}>sucesso!</span></h1>
            </div>
            <div className="row">
              <p style={{ fontFamily: "CiscoSans", color: "#000", marginBottom: "3vh", textAlign: "center", fontSize: "1.25em", margin: "0px 40px" }} >O link da reunião foi enviado para o seguinte email: 
                <span style={{ fontFamily: "CiscoSansBold"}}> {location.state.email}</span>
              </p>
              <p style={{ fontFamily: "CiscoSans", color: "#000", marginBottom: "3vh", textAlign: "center", fontSize: "1.25em", margin: "0px 40px" }} >Tema: 
                <span style={{ fontFamily: "CiscoSansBold"}}> {location.state.meetingName}</span>
              </p>
              <p style={{ fontFamily: "CiscoSans", color: "#000", marginBottom: "3vh", textAlign: "center", fontSize: "1.25em", margin: "0px 40px" }} >Horário: 
                <span style={{ fontFamily: "CiscoSansBold"}}> {location.state.startTime} - {location.state.endTime}</span>
              </p>
              <Button className="btn" style={{borderRadius: "30px", minWidth: "150px", minHeight: "5vh", fontFamily: "CiscoSans", backgroundColor: "#0054AF", marginTop: "3vh"}} onClick={() => navigate(-1)} type="button" node="button" waves="light">Voltar</Button>
            </div>
          </div>
          <div className="row center-align hide-on-large-only">
              <img src={teltecLogo} alt="Teltec Logo" style={{width: "10vw", minWidth: "175px", marginBottom: "1vh"}}></img>
          </div>
        </div>
      </div>
      <div className="hide-on-med-and-down" style={{position: 'absolute', bottom:"3.5vh", right:"1.5vw"}}>
        <img src={teltecLogo} alt="Teltec Logo" style={{width: "14vw"}}></img>
      </div>
    </div>
  );
};
