import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { LoadingAnimation } from "../../components/LoadingAnimation";
import { resetTokens } from "../../services/backend";

export const Reset = () => {
  const { id } = useParams();
  const [resetState, setResetState] = useState(false)

  resetTokens(id).then(res => {
    setResetState(true);
  });

  return (
    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "100vh"}}>
      {resetState ? <span>Token was successfully reset!</span> : <LoadingAnimation /> }
    </div>
  );
};

