import axios from "axios";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/v1`,
});

export const sendMail = async (formData) => {
  const dataObj = {
    email: formData.email,
    link: formData.link,
  };
  return instance
    .post("/sendMail", dataObj)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const saveTokens = async (authData) => {
  const dataObj = {
    id: authData.id,
    code: authData.code,
    state: authData.state,
  };
  return instance
    .post("/auth/save", dataObj)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const retrieveTokens = async (authId) => {
  return instance
    .get(`/auth/retrieve/${authId}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const resetTokens = async(authId) => {
  return instance
    .get(`/auth/reset/${authId}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
