import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useParams, Navigate, useLocation, useNavigate } from "react-router-dom";
import { postMeet } from "../../services/webex-services";
import { CustomTextInput } from "../../components/CustomTextInput/CustomTextInput";
import { Button } from "react-materialize";
import teltecLogo from "../../assets/img/logo_teltec.png"

export const PrivateScheduleForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { id } = useParams();
  const [status, setStatus] = useState("");
  const [formValues, setFormValues] = useState({});
  const [accessToken, setAccessToken] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  
  useEffect(() => {
    if(location.state) {
      setAccessToken(location.state.accessToken);
      if (refreshToken === "") {
        setRefreshToken(location.state.refreshToken);
      }
    }
  }, [location, refreshToken]);

  if (!boardId.hasOwnProperty(id)) {
    return <Navigate to="/" />;
  }

  if (status.status === 200) {
    return <Navigate to="/success" state={formValues} />;
  }

  if (!location.state) {
    return <Navigate to={`/load/${id}`} />;
  }

  return (
    <Formik
      initialValues={defaultValues}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={Yup.object({
        meetingName: Yup.string()
          .min(3, "* Necessário conter ao menos 3 caracteres")
          .required("* Obrigatório"),
        name: Yup.string()
          .min(3, "* Necessário conter ao menos 3 caracteres")
          .required("* Obrigatório"),
        email: Yup.string()
          .email("* Endereço de email inválido")
          .required("* Obrigatório"),
        startTime: Yup.string()
          .required("* Obrigatório")
          .test(
            "is-greater",
            "* Horário inválido, reuniões podem ser marcadas apenas a partir das 06:00",
            function (value) {
              return moment(value, "HH:mm").isSameOrAfter(
                moment("06:00", "HH:mm")
              );
            }
          ),
        endTime: Yup.string()
          .required("* Obrigatório")
          .test("is-greater", "* Horário de fim não pode ser anterior ao horário de inicio", function (value) {
            return moment(value, "HH:mm").isAfter(
              moment(this.parent.startTime, "HH:mm")
            );
          })
          .test("is-greater", "* A duração da reunião deve ser superior a 10 minutos", function (value) {
            return moment(value, "HH:mm").isAfter(
              moment(this.parent.startTime, "HH:mm").add(10, "minutes")
            );
          })
          .test(
            "is-lower",
            "* Horário inválido, reuniões podem não podem ser marcadas após às 20:00",
            function (value) {
              return moment(value, "HH:mm").isSameOrBefore(
                moment("20:00", "HH:mm")
              );
            }
          ),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        values.hostEmail = boardId[id].email;

        const checkStartTime = moment(values.startTime, "HH:mm");
        const currentTime = moment(new Date(), "HH:mm")
        values.startTime = moment.duration(currentTime.diff(checkStartTime)).asMinutes() < 1 ? checkStartTime.add(1, "minutes").format("HH:mm") : values.startTime;
        values.endTime = moment(values.endTime, "HH:mm").isAfter(moment(values.startTime, "HH:mm").add(10, "minutes")) ? values.endTime : moment(values.endTime, "HH:mm").add(1, "minutes").format("HH:mm");

        setFormValues(values);
        await postMeet(values, accessToken).then((req) => {
          req.status === 200
            ? setStatus({ status: req.status })
            : setStatus({ status: req.status, message: (req.data.errors && req.data.errors.length) > 0 ? req.data.errors[0].description : req.data.message});
        });
        // resetForm();
        setSubmitting(false);
      }}
    >
    {(props) => (
      <div className="row" style={{margin: "0", width: "100%", height: "100%"}}>
        <div className="col hide-on-med-and-down" style={{position: 'fixed', bottom:"3.5vh", left:"7.5vw"}}>
          <img src={teltecLogo} alt="Teltec Logo" style={{width: "12.5vw", minWidth: "190px"}}></img>
        </div>
        <div className="col s12 m12 l12" style={{height:"100%"}}>
          <div className="row center-align s12 m10 l8" style={{margin: "auto"}}>
            <div className="col center-align s12 m10 l8 offset-m1 offset-l2">
              <div className="card-panel white" style={{borderRadius: "35px", margin: "8vh auto"}}>
                <div className="row s10 m8 l8">
                  <h1 style={{ color: "#0054AF", margin: 0, marginTop: "3vh", textAlign: "center",  fontFamily: "CiscoSansBold", fontSize: "3em" }}>WEBEX</h1>
                  <h1 style={{ color: "#6BBF46", margin: 0, marginBottom: "3vh", textAlign: "center",  fontFamily: "CiscoSans", fontSize: "3em" }}>SELF-SERVICE</h1>
                  {status.status !== 200 && status.status !== undefined ? (
                    <>
                      <span style={{ color: "red", marginTop: "2vh", marginBottom: "2vh", textAlign: "center",  fontFamily: "CiscoSans"}} >Code {status.status}: {status.message}</span>
                    </>
                  ) : null}
                </div>
                <div className="row">
                  <p style={{ fontFamily: "CiscoSans", color: "gray", textAlign: "center", fontSize: "1.25em", margin: "0px 40px" }} >Para agendar sua reunião, preencha os campos abaixo e pressione o botão <span style={{ fontFamily: "CiscoSansBold"}} >Enviar</span></p>
                </div>
                  <div>
                    <Form>
                      <CustomTextInput label="Reunião*" name="meetingName" type="text"></CustomTextInput>
                      <CustomTextInput label="Digite seu nome*" name="name" type="text"></CustomTextInput>
                      <CustomTextInput label="Digite seu email pessoal*" name="email" type="email"></CustomTextInput>
                      <CustomTextInput label="Horário de ínicio*" name="startTime" type="time"></CustomTextInput>
                      <CustomTextInput label="Horário de fim*" name="endTime" type="time"></CustomTextInput>
                      <Button style={{borderRadius: "30px", minWidth: "150px", minHeight: "5vh", fontFamily: "CiscoSans", backgroundColor: "#0054AF"}} type="submit" node="button" waves="light">{props.isSubmitting ? "Enviando..." : "Enviar"}</Button>
                    </Form>
                  </div>
                  <div className="row s10 m8 l8">
                  <h1 style={{ color: "#0054AF", margin: 0, marginTop: "6vh", marginBottom: "1vh", textAlign: "center",  fontFamily: "CiscoSansBold", fontSize: "2em" }}>MANUAIS <span style={{fontFamily: "CiscoSans"}}>DE USO</span></h1>
                  </div>
                  <div className="row center">
                    <Button className="btn" style={{borderRadius: "30px", minWidth: "150px", minHeight: "5vh", fontFamily: "CiscoSans", backgroundColor: "#6BBF46"}} onClick={() => navigate("/guides")} type="button" node="button" waves="light">Acessar</Button>
                  </div>
                  <div className="row center-align hide-on-large-only" style={{marginBottom: "-100px"}}>
                  <img src={teltecLogo} alt="Teltec Logo" style={{width: "10vw", minWidth: "175px", marginBottom: "1vh"}}></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col hide-on-med-and-down" style={{position: 'fixed', bottom:"3.5vh", right:"7.5vw"}}>
          <img src={teltecLogo} alt="Teltec Logo" style={{width: "12.5vw", minWidth: "190px"}}></img>
        </div>
      </div>
    )}
    </Formik>
  );
};

const defaultValues = {
  meetingName: "",
  name: "",
  email: "",
  startTime: "",
  endTime: "",
};

const boardId = {
  "auditorio": {
    email: "board-auditorio@acate.com.br",
    desc: "Board Auditório ACATE",
  },
  "llreunioes": {
    email: "board.llreunioes@acate.com.br",
    desc: "Sala de Reuniões LinkLab",
  },
  "linklab": {
    email: "board.linklab@acate.com.br",
    desc: "Coworking Linklab",
  },
  "acate": {
    email: "board.acate@acate.com.br",
    desc: "Sala de Reuniões ACATE",
  },
  "observatorio": {
    email: "board.observatorio@acate.com.br",
    desc: "Observatório ACATE",
  },
  "actexpdual": {
    email: "board.actexpdual@acate.com.br",
    desc: "Sala de Reuniões ACATE Experience",
  },
  "showroom": {
    email: "board.showroom@acate.com.br",
    desc: "Showroom ACATE Experience",
  },
  "sala-r10": {
    email: "board-sala-r10@acate.com.br",
    desc: "Board Sala R10 ACATE",
  },
  "jumproom": {
    email: "board.jumproom@acate.com.br",
    desc: "Board Mesa de Reuniões Jumproom",
  }
};
