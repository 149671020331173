import React from "react";
import teltecLogo from "../../assets/img/logo_teltec.png"

export const NotFound = () => {
  return (
    <div className="row s12 m12 l12" style={{margin: "0", width: "100%", height: "100%"}}>
    <div className="hide-on-med-and-down" style={{position: 'absolute', bottom:"3.5vh", left:"1.5vw"}}>
      <img src={teltecLogo} alt="Teltec Logo" style={{width: "14vw"}}></img>
    </div>
    <div className="col center-align s12 m12 l12" style={{}}>
      <div className="row center-align s12 m10 l8" style={{}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "100vh"}}>
          <div className="card-panel white" style={{borderRadius: "35px", margin: "3px", minWidth: "50vw"}}>
            <div className="row s12 m12 l12" style={{margin: "5vh 0"}}>
              <h1 style={{ color: "#0054AF", margin: 0, textAlign: "center",  "font-family": "CiscoSansBold", fontSize: "3em" }}>Erro 404 - Página não encontrada!</h1>
            </div>
          </div>
            <div className="row center-align hide-on-large-only">
              <img src={teltecLogo} alt="Teltec Logo" style={{width: "10vw", minWidth: "175px", marginTop: "5vh"}}></img>
            </div>
        </div>
      </div>
    </div>
    <div className="hide-on-med-and-down" style={{position: 'absolute', bottom:"3.5vh", right:"1.5vw"}}>
      <img src={teltecLogo} alt="Teltec Logo" style={{width: "14vw"}}></img>
    </div>
  </div>
  );
};
