import React from "react";
import { Button } from "react-materialize";
import teltecLogo from "../../assets/img/logo_teltec.png"
import googleMeetGuide from "../../assets/img/Google Meet-1.png"
import webexGuide from "../../assets/img/Webex-1.png"
import webexLobbyGuide1 from "../../assets/img/webex_lobby-1.png"
import webexLobbyGuide2 from "../../assets/img/webex_lobby-2.png"
import webexLobbyGuide3 from "../../assets/img/webex_lobby-3.png"
import webexLobbyGuide4 from "../../assets/img/webex_lobby-4.png"
import agendarReuniaoMP4 from "../../assets/videos/agendar_reuniao.mp4"
import agendarWebinarMP4 from "../../assets/videos/agendar_webinar.mp4"
import howToLogin from "../../assets/videos/comologar.mp4"
import { Collapsible, CollapsibleItem, Icon } from "react-materialize";
import { useNavigate } from "react-router-dom";

export const Guides = () => {
  const navigate = useNavigate();

  return (
    <div className="row s12 m12 l12" style={{margin: "0", width: "100%", height: "100%"}}>
      <div className="hide-on-med-and-down" style={{position: 'fixed', bottom:"3.5vh", left:"1.5vw"}}>
        <img src={teltecLogo} alt="Teltec Logo" style={{width: "14vw"}}></img>
      </div>
      <div className="col center-align s12 m12 l12" style={{marginTop: "12vh"}}>
        <div className="row center-align s12 m10 l8" style={{margin: "3vh auto"}}>
          <div className="card-panel white" style={{margin: "3px"}}>
            <div className="row s12 m12 l12" style={{margin: "5vh 0"}}>
              <h1 style={{ color: "#0054AF", margin: 0, textAlign: "center",  "font-family": "CiscoSansBold", fontSize: "3em" }}>MANUAIS <span style={{"font-family": "CiscoSans"}}>DE USO</span></h1>
            </div>
          </div>
          <Collapsible
            accordion={false}
            popout
          >
            <CollapsibleItem
              expanded={false}
              header="Como realizar o login no Webex"
              node="div"
              icon={<Icon>expand_more</Icon>}
              style={{backgroundColor: "white", margin: "3px"}}
            >
            <div className="card-horizontal large">
              <div className="card-image">
              <video src={howToLogin} controls type="video/mp4" alt="Como logar no Webex" style={{width: "100%", height: "100%", objectFit: "cover"}}></video>
              </div>
            </div>  
            </CollapsibleItem>
            <CollapsibleItem
              expanded={false}
              header="Como agendar uma reunião no Webex"
              node="div"
              icon={<Icon>expand_more</Icon>}
              style={{backgroundColor: "white", margin: "3px"}}
            >
            <div className="card-horizontal large">
              <div className="card-image">
                <video src={agendarReuniaoMP4} controls type="video/mp4" alt="Como agendar uma reunião no webex" style={{width: "100%", height: "100%", objectFit: "cover"}}></video>
              </div>
            </div>  
            </CollapsibleItem>
            <CollapsibleItem
                expanded={false}
                header="Como agendar um webinar no Webex"
                node="div"
                icon={<Icon>expand_more</Icon>}
                style={{backgroundColor: "white", margin: "3px"}}
              >
              <div className="card-horizontal large">
                <div className="card-image">
                <video src={agendarWebinarMP4} controls type="video/mp4" alt="Como agendar um webinar" style={{width: "100%", height: "100%", objectFit: "cover"}}></video>
                </div>
              </div>  
            </CollapsibleItem>
            <CollapsibleItem
                expanded={false}
                header="Como ingressar em reuniões no Webex"
                node="div"
                icon={<Icon>expand_more</Icon>}
                style={{backgroundColor: "white", margin: "3px"}}
              >
                <div className="card-horizontal large">
                  <div className="card-image">
                    <img src={webexGuide} alt="Webex Guide" style={{width: "100%", height: "100%", objectFit: "cover"}}></img>
                  </div>
                </div>
            </CollapsibleItem>
            <CollapsibleItem
              expanded={false}
              header="Como configurar o lobby no Webex"
              node="div"
              icon={<Icon>expand_more</Icon>}
              style={{backgroundColor: "white", margin: "3px"}}
            >
              <div className="card-horizontal large">
                <div className="card-image">
                  <img src={webexLobbyGuide1} alt="Webex Lobby Guide 1" style={{width: "100%", height: "100%", objectFit: "cover"}}></img>
                  <img src={webexLobbyGuide2} alt="Webex Lobby Guide 2" style={{width: "100%", height: "100%", objectFit: "cover"}}></img>
                  <img src={webexLobbyGuide3} alt="Webex Lobby Guide 3" style={{width: "100%", height: "100%", objectFit: "cover"}}></img>
                  <img src={webexLobbyGuide4} alt="Webex Lobby Guide 4" style={{width: "100%", height: "100%", objectFit: "cover"}}></img>
                </div>
              </div>
            </CollapsibleItem>
            <CollapsibleItem
              expanded={false}
              header="Como ingressar em reuniões no Google Meet"
              node="div"
              icon={<Icon>expand_more</Icon>}
              style={{backgroundColor: "white", margin: "3px"}}
            >
              <div className="card-horizontal large">
                <div className="card-image">
                  <img src={googleMeetGuide} alt="Google Meet Guide" style={{width: "100%", height: "100%", objectFit: "cover"}}></img>
                </div>
              </div>
            </CollapsibleItem>
          </Collapsible>
          <Button className="btn" style={{borderRadius: "30px", minWidth: "150px", minHeight: "5vh", fontFamily: "CiscoSans", backgroundColor: "#0054AF", marginTop: "3vh"}} onClick={() => navigate(-1)} type="button" node="button" waves="light">Voltar</Button>
          <div className="row center-align hide-on-large-only">
              <img src={teltecLogo} alt="Teltec Logo" style={{width: "10vw", minWidth: "175px", marginTop: "3vh"}}></img>
          </div>
        </div>
      </div>
      <div className="hide-on-med-and-down" style={{position: 'fixed', bottom:"3.5vh", right:"1.5vw"}}>
        <img src={teltecLogo} alt="Teltec Logo" style={{width: "14vw"}}></img>
      </div>
    </div>
  );
};
