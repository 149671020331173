import axios from "axios";
import { getUTCdate } from "../utils/utils";

const instance = axios.create({
  baseURL: "https://webexapis.com/v1/meetings",
});

export const getMeet = () => {
  return instance.get().then((response) => {
    return response.data;
  });
};

export const postMeet = async (formData, auth) => {
  const { startTime, endTime } = formData;
  const newMeet = {
    title: formData.meetingName,
    hostEmail: formData.hostEmail,
    invitees: [
      {
        email: formData.email,
        displayName: formData.name,
        coHost: false,
      },
    ],
    start: getUTCdate(startTime),
    end: getUTCdate(endTime),
    timezone: "America/Sao_Paulo",
    sendEmail: true,
  };

  return instance
    .post("/", newMeet, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    })
    .then((req) => {
      return req;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
};
