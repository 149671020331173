import React, { useState, useEffect } from "react";
import { useParams, useLocation, Navigate } from "react-router-dom";
import { LoadingAnimation } from "../../components/LoadingAnimation";
import { retrieveTokens, saveTokens } from "../../services/backend";

export const SchedulerLoad = () => {
  const { id } = useParams();
  const search = useLocation().search;

  const [token, setToken] = useState({});
  const [status, setStatus] = useState(0);
  const [validateProcess, setValidateProcess] = useState(false);
  const [failProcess, setFailProcess] = useState(false);

  const code = new URLSearchParams(search).get("code");
  const state = new URLSearchParams(search).get("state");
  
  useEffect(() => {
    if (status === 0 && !code && !state) {
      retrieveTokens(id).then((obj) => {
        if(obj.data.status === "data") {
          setToken(obj.data)
          setValidateProcess(true);
          setStatus(1);
        } 
        else if(obj.data.status === "link") {
          window.location.href = obj.data.data
        }
      })
    } else if(status !== 1) {
      const reqData = {
        id: id,
        code: code,
        state: state,
      }
      saveTokens(reqData)
        .then((obj) => {
          retrieveTokens(id).then((obj) => {
            if(obj.data.status === "data") {
              setToken(obj.data)
              setValidateProcess(true);
              setStatus(1);
            } 
          })
        })
        .catch((err) => {
          setFailProcess(true);
        });
    }
  }, [id, status, code, state]);
  
  if (status === 1 && validateProcess === true && failProcess === false) {
    return <Navigate to={`/meeting/${id}`} state={token.data} />;
  }

  return (
    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "100vh"}}>
      {failProcess ? <span>Loading error</span> : <LoadingAnimation /> }
    </div>
  );
};
