import React from "react";
import { useField } from "formik";

export const CustomTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="row">
      <div className="input-field col s12 l10 offset-l1">
        <input
          className={meta.touched && meta.error ? "invalid" : "validate"}
          placeholder={props.type === "time" ? "" : null}
          {...field}
          {...props}
        />
        <label htmlFor={props.name} style={{"pointerEvents": "none"}}>{label}</label>
        {meta.touched && meta.error ? (
          <span className="helper-text left" data-error={meta.error}>
            {meta.error}
          </span>
        ) : null}
      </div>
    </div>
  );
};
