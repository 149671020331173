import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PrivateScheduleForm } from "../pages/PrivateScheduleForm";
import { NotFound } from "../pages/NotFound";
import { Success } from "../pages/Success";
import { SchedulerLoad } from "../pages/SchedulerLoad";
import { Reset } from "../pages/Reset";
import { Guides } from "../pages/Guides";

export function MainRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/load/:id" element={<SchedulerLoad />} />
        <Route path="/reset/:id" element={<Reset />} />
        <Route path="/meeting/:id" element={<PrivateScheduleForm />} />
        <Route path="/success" element={<Success  />} />
        <Route path="/guides" element={<Guides />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}
